<template>
  <b-container fluid>
    <b-row>
        <b-col lg="12" sm="12">
            <iq-card>
                <template v-slot:body>
                    <b-overlay :show="loading">
                        <template>
                            <div style="font-size:18px; background-color: #005B5B;">
                            <h5 class="text-white text-center">
                                {{ $t('admission_form.gen_inf') }}
                            </h5>
                            </div>
                        </template>
                        <b-table-simple bordered>
                            <b-tr>
                                <b-th>{{ $t('admission_form.circular_memo') }}</b-th>
                                <b-td>{{ ($i18n.locale=='bn') ? application.cir_name_bn : application.cir_name }}</b-td>
                                <b-th>{{ $t('admission_form.candidate_type') }}</b-th>
                                <b-td>{{ ($i18n.locale=='bn') ? application.canType_name_bn : application.canType_name }}</b-td>
                                <b-th>{{ $t('admission_form.candidate_org') }}</b-th>
                                <b-td>{{ ($i18n.locale=='bn') ? application.canOrg_name_bn : application.canOrg_name }}</b-td>
                            </b-tr>
                            <b-tr>
                                <b-th>{{ $t('admission_form.field_study') }}</b-th>
                                <b-td>{{ ($i18n.locale=='bn') ? application.field_of_study_bn : application.field_of_study }}</b-td>
                                <b-th>{{ $t('educational_management.name') }}</b-th>
                                <b-td>{{ ($i18n.locale=='bn') ? application.name_bn : application.name }}</b-td>
                                <b-th>{{ $t('externalUserIrrigation.father_name') }}</b-th>
                                <b-td>{{ ($i18n.locale=='bn') ? application.father_name_bn : application.father_name }}</b-td>
                            </b-tr>
                            <b-tr>
                                <b-th>{{ $t('externalUserIrrigation.mother_name') }}</b-th>
                                <b-td>{{ ($i18n.locale=='bn') ? application.mother_name_bn : application.mother_name }}</b-td>
                                <b-th>{{ $t('externalUserIrrigation.date_of_birth') }}</b-th>
                                <b-td>{{ application.date_of_birth | dateFormat }}</b-td>
                                <b-th>{{ $t('admission_form.age') }}</b-th>
                                <b-td>{{ $n(application.age) }}</b-td>
                            </b-tr>
                            <b-tr>
                                <b-th>{{ $t('admission_form.place_of_birth') }}</b-th>
                                <b-td>{{ ($i18n.locale=='bn') ? application.place_of_birth : application.place_of_birth }}</b-td>
                                <b-th>{{ $t('admission_form.mat_status') }}</b-th>
                                <b-td>{{ ($i18n.locale=='bn') ? application.mStatus_name_bn : application.mStatus_name }}</b-td>
                                <b-th>{{ $t('admission_form.nid_no') }}</b-th>
                                <b-td>{{ application.nid }}</b-td>
                            </b-tr>
                            <b-tr>
                                <b-th>{{ $t('admission_form.mobile_no') }}</b-th>
                                <b-td>{{ ($i18n.locale=='bn') ? application.mobile_no : application.mobile_no }}</b-td>
                                <b-th>{{ $t('admission_form.email') }}</b-th>
                                <b-td>{{ ($i18n.locale=='bn') ? application.email : application.email }}</b-td>
                                <b-th>{{ $t('educational_management.designation') }}</b-th>
                                <b-td>{{ ($i18n.locale=='bn') ? application.designation_bn : application.designation }}</b-td>
                            </b-tr>
                        </b-table-simple>
                        <template>
                            <div style="font-size:18px; background-color: #005B5B;">
                            <h5 class="text-white text-center">
                                {{ $t('admission_form.add_info') }}
                            </h5>
                            </div>
                        </template>
                        <b-table-simple bordered>
                            <b-tr>
                                <b-th colspan="6" class="text-center">{{ $t('educational_management.present_business_address') }}</b-th>
                            </b-tr>
                            <b-tr>
                                <b-th>{{ $t('org_pro.area_type') }}</b-th>
                                <b-td>{{ ($i18n.locale=='bn') ? addressInfo.area_name_bn : addressInfo.area_name }}</b-td>
                                <b-th>{{ $t('org_pro_division.division') }}</b-th>
                                <b-td>{{ ($i18n.locale=='bn') ? addressInfo.div_name_bn : addressInfo.div_name }}</b-td>
                                <b-th>{{ $t('org_pro_district.district') }}</b-th>
                                <b-td>{{ ($i18n.locale=='bn') ? addressInfo.dis_name_bn : addressInfo.dis_name }}</b-td>
                            </b-tr>
                            <b-tr>
                                <b-th>{{ $t('org_pro_upazilla.upazilla') }}</b-th>
                                <b-td>{{ ($i18n.locale=='bn') ? addressInfo.upa_name_bn : addressInfo.upa_name }}</b-td>
                                <b-th>{{ $t('org_pro_union.union') }}</b-th>
                                <b-td>{{ ($i18n.locale=='bn') ? addressInfo.union_name_bn : addressInfo.union_name }}</b-td>
                                <b-th>{{ $t('org_pro.city_corporation') }}</b-th>
                                <b-td>{{ ($i18n.locale=='bn') ? addressInfo.cityCor_name_bn : addressInfo.cityCor_name }}</b-td>
                            </b-tr>
                            <b-tr>
                                <b-th>{{ $t('org_pro.pauroshoba') }}</b-th>
                                <b-td>{{ ($i18n.locale=='bn') ? addressInfo.pauro_name_bn : addressInfo.pauro_name }}</b-td>
                                <b-th>{{ $t('org_pro.ward') }}</b-th>
                                <b-td>{{ ($i18n.locale=='bn') ? addressInfo.ward_name_bn : addressInfo.ward_name }}</b-td>
                                <b-th>{{ $t('globalTrans.village') }}</b-th>
                                <b-td>{{ ($i18n.locale=='bn') ? addressInfo.village_name_bn : addressInfo.village_name }}</b-td>
                            </b-tr>
                            <b-tr>
                                <b-th>{{ $t('admission_form.house_number') }}</b-th>
                                <b-td>{{ ($i18n.locale=='bn') ? addressInfo.house_number_bn : addressInfo.house_number }}</b-td>
                            </b-tr>
                        </b-table-simple>
                        <b-table-simple bordered>
                            <b-tr>
                                <b-th colspan="6" class="text-center">{{ $t('educational_management.permanent_address') }}</b-th>
                            </b-tr>
                            <b-tr>
                                <b-th>{{ $t('org_pro.area_type') }}</b-th>
                                <b-td>{{ ($i18n.locale=='bn') ? addressInfo.per_area_name_bn : addressInfo.per_area_name }}</b-td>
                                <b-th>{{ $t('org_pro_division.division') }}</b-th>
                                <b-td>{{ ($i18n.locale=='bn') ? addressInfo.per_div_name_bn : addressInfo.per_div_name }}</b-td>
                                <b-th>{{ $t('org_pro_district.district') }}</b-th>
                                <b-td>{{ ($i18n.locale=='bn') ? addressInfo.per_dis_name_bn : addressInfo.per_dis_name }}</b-td>
                            </b-tr>
                            <b-tr>
                                <b-th>{{ $t('org_pro_upazilla.upazilla') }}</b-th>
                                <b-td>{{ ($i18n.locale=='bn') ? addressInfo.per_upa_name_bn : addressInfo.per_upa_name }}</b-td>
                                <b-th>{{ $t('org_pro_union.union') }}</b-th>
                                <b-td>{{ ($i18n.locale=='bn') ? addressInfo.per_union_name_bn : addressInfo.per_union_name }}</b-td>
                                <b-th>{{ $t('org_pro.city_corporation') }}</b-th>
                                <b-td>{{ ($i18n.locale=='bn') ? addressInfo.per_cityCor_name_bn : addressInfo.per_cityCor_name }}</b-td>
                            </b-tr>
                            <b-tr>
                                <b-th>{{ $t('org_pro.pauroshoba') }}</b-th>
                                <b-td>{{ ($i18n.locale=='bn') ? addressInfo.per_pauro_name_bn : addressInfo.per_pauro_name }}</b-td>
                                <b-th>{{ $t('org_pro.ward') }}</b-th>
                                <b-td>{{ ($i18n.locale=='bn') ? addressInfo.per_ward_name_bn : addressInfo.per_ward_name }}</b-td>
                                <b-th>{{ $t('globalTrans.village') }}</b-th>
                                <b-td>{{ ($i18n.locale=='bn') ? addressInfo.per_village_name_bn : addressInfo.per_village_name }}</b-td>
                            </b-tr>
                            <b-tr>
                                <b-th>{{ $t('admission_form.house_number') }}</b-th>
                                <b-td>{{ ($i18n.locale=='bn') ? addressInfo.per_house_number_bn : addressInfo.per_house_number }}</b-td>
                            </b-tr>
                        </b-table-simple>
                        <template>
                            <div style="font-size:18px; background-color: #005B5B;">
                            <h5 class="text-white text-center">
                                {{ $t('admission_form.lang_skill_info') }}
                            </h5>
                            </div>
                        </template>
                        <b-table-simple bordered>
                            <b-tr>
                                <b-th>{{ $t('admission_form.english_reading_skill') }}</b-th>
                                <b-td>{{ (typeof languageInfo !== 'undefined') ? ($i18n.locale=='bn') ? LanguageInfo.eReading_name_bn : LanguageInfo.eReading_name : ''}}</b-td>
                                <b-th>{{ $t('admission_form.english_writing_skill') }}</b-th>
                                <b-td>{{ (typeof languageInfo !== 'undefined') ? ($i18n.locale=='bn') ? LanguageInfo.eWriting_name_bn : LanguageInfo.eWriting_name : ''}}</b-td>
                                <b-th>{{ $t('admission_form.english_speaking_skill') }}</b-th>
                                <b-td>{{ (typeof languageInfo !== 'undefined') ? ($i18n.locale=='bn') ? LanguageInfo.eSpeaking_name_bn : LanguageInfo.eSpeaking_name : ''}}</b-td>
                            </b-tr>
                        </b-table-simple>
                        <template>
                            <div style="font-size:18px; background-color: #005B5B;">
                                <h5 class="text-white text-center">
                                    {{ $t('admission_form.aca_record_info') }}
                                </h5>
                            </div>
                        </template>
                        <b-table-simple bordered>
                            <b-tr>
                                <b-th>{{ $t('admission_form.education_level') }}</b-th>
                                <b-th>{{ $t('admission_form.title_degree') }}</b-th>
                                <b-th>{{ $t('admission_form.group') }}</b-th>
                                <b-th>{{ $t('admission_form.name_inst') }}</b-th>
                                <b-th>{{ $t('admission_form.inst_address') }}</b-th>
                                <b-th>{{ $t('admission_form.year_attend_from') }}</b-th>
                                <b-th>{{ $t('admission_form.year_attend_to') }}</b-th>
                                <b-th>{{ $t('admission_form.score_sys') }}</b-th>
                                <b-th>{{ $t('admission_form.class') }}</b-th>
                                <b-th>{{ $t('admission_form.pass_year') }}</b-th>
                            </b-tr>
                            <b-tr v-for="(accadamicData, index) in AccadamicInfo" :key="index">
                                <b-td>{{ ($i18n.locale=='bn') ? accadamicData.education_name_bn : accadamicData.education_name }}</b-td>
                                <b-td>{{ ($i18n.locale=='bn') ? accadamicData.degree_title_bn : accadamicData.degree_title }}</b-td>
                                <b-td>{{ ($i18n.locale=='bn') ? accadamicData.group_bn : accadamicData.group }}</b-td>
                                <b-td>{{ ($i18n.locale=='bn') ? accadamicData.institute_bn : accadamicData.institute }}</b-td>
                                <b-td>{{ ($i18n.locale=='bn') ? accadamicData.address_bn : accadamicData.address }}</b-td>
                                <b-td>{{ ($i18n.locale=='bn') ? accadamicData.year_from : accadamicData.year_from }}</b-td>
                                <b-td>{{ ($i18n.locale=='bn') ? accadamicData.year_to : accadamicData.year_to }}</b-td>
                                <b-td>{{ ($i18n.locale=='bn') ? accadamicData.score_name_bn : accadamicData.score_name }}</b-td>
                                <b-td>{{ ($i18n.locale=='bn') ? accadamicData.class_or_grade_id : accadamicData.class_or_grade_id }}</b-td>
                                <b-td>{{ ($i18n.locale=='bn') ? accadamicData.passing_year : accadamicData.passing_year }}</b-td>
                            </b-tr>
                        </b-table-simple>
                        <template>
                            <div style="font-size:18px; background-color: #005B5B;">
                                <h5 class="text-white text-center">
                                    {{ $t('admission_form.training_details') }}
                                </h5>
                            </div>
                        </template>
                        <b-table-simple bordered>
                            <b-tr>
                                <b-th>{{ $t('admission_form.training_type') }}</b-th>
                                <b-th>{{ $t('admission_form.training_title') }}</b-th>
                                <b-th>{{ $t('admission_form.batch') }}</b-th>
                                <b-th>{{ $t('admission_form.venue') }}</b-th>
                                <b-th>{{ $t('globalTrans.from_date') }}</b-th>
                                <b-th>{{ $t('globalTrans.to_date') }}</b-th>
                            </b-tr>
                            <b-tr v-for="(trainingData, index) in trainingDetails" :key="index">
                                <b-td>{{ ($i18n.locale=='bn') ? trainingData.tType_name_bn : trainingData.tType_name }}</b-td>
                                <b-td>{{ ($i18n.locale=='bn') ? trainingData.tTitle_name_bn : trainingData.tTitle_name }}</b-td>
                                <b-td>{{ ($i18n.locale=='bn') ? trainingData.batch : trainingData.batch }}</b-td>
                                <b-td>{{ ($i18n.locale=='bn') ? trainingData.venue_bn : trainingData.venue }}</b-td>
                                <b-td>{{ trainingData.from_date | dateFormat }}</b-td>
                                <b-td>{{ trainingData.to_date | dateFormat }}</b-td>
                            </b-tr>
                        </b-table-simple>
                        <template>
                            <div style="font-size:18px; background-color: #005B5B;">
                            <h5 class="text-white text-center">
                                {{ $t('admission_form.seminar') }}
                            </h5>
                            </div>
                        </template>
                        <b-table-simple bordered>
                            <b-tr>
                                <b-th>{{ $t('admission_form.semi_name') }}</b-th>
                                <b-th>{{ $t('admission_form.semi_v') }}</b-th>
                                <b-th>{{ $t('globalTrans.from_date') }}</b-th>
                                <b-th>{{ $t('globalTrans.to_date') }}</b-th>
                            </b-tr>
                            <b-tr v-for="(seminarData, index) in seminarDetails" :key="index">
                                <b-td>{{ ($i18n.locale=='bn') ? seminarData.seminar_name_bn : seminarData.seminar_name }}</b-td>
                                <b-td>{{ ($i18n.locale=='bn') ? seminarData.venue_bn : seminarData.venue }}</b-td>
                                <b-td>{{ seminarData.from_date | dateFormat }}</b-td>
                                <b-td>{{ seminarData.to_date | dateFormat }}</b-td>
                            </b-tr>
                        </b-table-simple>
                        <template v-if="annexure1Details !== null">
                            <div style="font-size:18px; background-color: #005B5B;">
                            <h5 class="text-white text-center">
                                {{ $t('admission_form.qualification') }}
                            </h5>
                            </div>
                        </template>
                        <b-table-simple bordered v-if="qulificationDetails !== null">
                            <b-tr>
                                <b-th style="width:50%">{{ $t('admission_form.other_qua') }}</b-th>
                                <b-td>{{ ($i18n.locale=='bn') ? qulificationDetails.qualification_bn : qulificationDetails.qualification }}</b-td>
                            </b-tr>
                        </b-table-simple>
                        <template v-if="annexure1Details !== null">
                            <div style="font-size:18px; background-color: #005B5B;">
                                <h5 class="text-white text-center">
                                    {{ $t('admission_form.annexure_1') }}
                                </h5>
                            </div>
                        </template>
                        <b-table-simple bordered v-if="annexure1Details !== null">
                            <b-tr>
                                <b-th style="width:25%">{{ $t('admission_form.evidence_of_contribution') }}</b-th>
                                <b-td style="width:25%">{{ ($i18n.locale=='bn') ? annexure1Details.evidence_bn : annexure1Details.evidence }}</b-td>
                                <b-th style="width:25%">{{ $t('admission_form.membership_of_society') }}</b-th>
                                <b-td style="width:25%">{{ ($i18n.locale=='bn') ? annexure1Details.membership_bn : annexure1Details.membership }}</b-td>
                            </b-tr>
                        </b-table-simple>
                        <template>
                            <div style="font-size:18px; background-color: #005B5B;">
                            <h5 class="text-white text-center">
                                {{ $t('admission_form.annexure_2') }}
                            </h5>
                            </div>
                        </template>
                        <b-table-simple bordered>
                            <b-tr>
                                <b-th>{{ $t('admission_form.organization') }}</b-th>
                                <b-th>{{ $t('admission_form.position_name') }}</b-th>
                                <b-th>{{ $t('admission_form.place_of_posting') }}</b-th>
                                <b-th>{{ $t('admission_form.employement_start_date') }}</b-th>
                                <b-th>{{ $t('admission_form.employement_end_date') }}</b-th>
                                <b-th>{{ $t('admission_form.remark') }}</b-th>
                            </b-tr>
                            <b-tr v-for="(annexure2Data, index) in annexure2Details" :key="index">
                                <b-td>{{ annexure2Data.org_id !== 0 ? getOrganization(annexure2Data.org_id) : annexure2Data.other_organization }}</b-td>
                                <b-td>{{ ($i18n.locale=='bn') ? annexure2Data.position_bn : annexure2Data.position }}</b-td>
                                <b-td>{{ ($i18n.locale=='bn') ? annexure2Data.posting_bn : annexure2Data.posting }}</b-td>
                                <b-td>{{ annexure2Data.start_date | dateFormat }}</b-td>
                                <b-td>{{ annexure2Data.end_date | dateFormat }}</b-td>
                                <b-td>{{ ($i18n.locale=='bn') ? annexure2Data.remark_bn : annexure2Data.remark }}</b-td>
                            </b-tr>
                        </b-table-simple>
                        <template>
                            <div style="font-size:18px; background-color: #005B5B;">
                            <h5 class="text-white text-center">
                                {{ $t('admission_form.annexure_3_info') }}
                            </h5>
                            </div>
                        </template>
                        <b-table-simple bordered>
                            <b-tr>
                                <b-th style="width:30%">{{ $t('admission_form.e_year') }}</b-th>
                                <b-th style="width:40%">{{ $t('admission_form.e_performance') }}</b-th>
                                 <b-th style="width:30%">{{ $t('globalTrans.attachment') }}</b-th>
                            </b-tr>
                            <b-tr v-for="(annexure3Data, index) in annexure3Details" :key="index">
                                <b-td>{{ ($i18n.locale=='bn') ? annexure3Data.year_name_bn : annexure3Data.year_name }}</b-td>
                                <b-td>{{ ($i18n.locale=='bn') ? annexure3Data.type_name_bn : annexure3Data.type_name }}</b-td>
                                <b-td>
                                    <a target="_blank" v-if="annexure3Data.attachment" :href="incentiveGrantServiceBaseUrl + 'storage/annexureThree/' + annexure3Data.attachment"><i class="fa fa-cloud" aria-hidden="true"></i> {{ $t('globalTrans.attachment_download') }}</a>
                                </b-td>
                            </b-tr>
                        </b-table-simple>
                    </b-overlay>
                </template>
            </iq-card>
        </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import RestApi, { incentiveGrantServiceBaseUrl } from '@/config/api_config'
import { applicantDetails } from '../../api/routes'
import flatpickr from 'flatpickr'
import ExportPdf from './export-pdf_details'

export default {
    props: ['id'],
    created () {
        if (this.id) {
            this.getSchemeApplicationDetails()
            this.application = this.id
            this.addressInfo = this.id
            this.LanguageInfo = this.id
            this.AccadamicInfo = this.id
            this.trainingDetails = this.id
            this.seminarDetails = this.id
            this.qulificationDetails = this.id
            this.annexure1Details = this.id
            this.annexure2Details = this.id
            this.annexure3Details = this.id
        }
    },
    data () {
        return {
            incentiveGrantServiceBaseUrl: incentiveGrantServiceBaseUrl,
            loading: true,
            pumpOperatorApp: '',
            application: [],
            addressInfo: [],
            LanguageInfo: [],
            AccadamicInfo: [],
            trainingDetails: [],
            seminarDetails: [],
            qulificationDetails: [],
            annexure1Details: [],
            annexure2Details: [],
            annexure3Details: [],
            approvals: [],
            surveys: [],
            slOffset: 1,
            candidateType: [
                { value: 1, text_en: 'Principle', text_bn: 'নীতি' },
                { value: 2, text_en: 'Alternative', text_bn: 'বিকল্প' }
            ],
            candidateOrg: [
                { value: 1, text_en: 'Under Ministry', text_bn: 'মন্ত্রীর অধীনে' },
                { value: 2, text_en: 'Others', text_bn: 'অন্যান্য' }
            ],
            maritalStatus: [
                { value: 1, text_en: 'Single', text_bn: 'অবিবাহিত' },
                { value: 2, text_en: 'Married', text_bn: 'বিবাহিত' },
                { value: 3, text_en: 'Other', text_bn: 'অন্যান্য' }
            ],
            getAreaType: [
                { value: 1, text_en: 'City Corpoation', text_bn: 'সিটি কর্পোরেশন' },
                { value: 2, text_en: 'Pauroshoba', text_bn: 'পৌরশোবা' },
                { value: 3, text_en: 'Union', text_bn: 'ইউনিয়ন' }
            ],
            englishSkill: [
                {
                    id: 1,
                    name: 'Excellent',
                    name_bn: 'দুর্দান্ত'
                },
                {
                    id: 2,
                    name: 'Good',
                    name_bn: 'ভাল'
                },
                {
                    id: 3,
                    name: 'Fair',
                    name_bn: 'চলনসই'
                }
            ],
            scoreSysList: [
                { value: 1, text: 'Old', text_bn: 'পুরাতন' },
                { value: 2, text: 'new', text_bn: 'নতুন' }
            ],
            traininTypeList: [
                { value: 1, text: 'Short Term', text_bn: 'স্বল্প মেয়াদ' },
                { value: 2, text: 'Long Term', text_bn: 'দীর্ঘ মেয়াদী' }
            ],
            traininTitleList: [
                { value: 1, text: 'Foundation Training', text_bn: 'ফাউন্ডেশন প্রশিক্ষণ' },
                { value: 2, text: 'Other Training', text_bn: 'অন্যান্য প্রশিক্ষণ' }
            ],
            performanceEvaluationList: [
                {
                    id: 1,
                    name: 'Extra Ordinary',
                    name_bn: 'অসাধারণ'
                },
                {
                    id: 2,
                    name: 'Very Good',
                    name_bn: 'খুব ভালো'
                },
                {
                    id: 3,
                    name: 'Good',
                    name_bn: 'ভালো'
                }
            ],
            evaluationYearList: [
                {
                    id: 1,
                    name: '2015',
                    name_bn: '২০১৫'
                },
                {
                    id: 2,
                    name: '2016',
                    name_bn: '২০১৬'
                },
                {
                id: 3,
                    name: '2017',
                    name_bn: '২০১৭'
                },
                {
                id: 4,
                    name: '2018',
                    name_bn: '২০১৮'
                },
                {
                    id: 5,
                    name: '2019',
                    name_bn: '২০১৯'
                },
                {
                    id: 6,
                    name: '2020',
                    name_bn: '২০২০'
                }
            ]
        }
    },
    mounted () {
        core.index()
        flatpickr('#registration_date', {})
        flatpickr('#deposit_date', {})
        flatpickr('#expire_date', {})
        flatpickr('#licence_issue_date', {})
        flatpickr('#licence_expire_date', {})
    },
    methods: {
        async getSchemeApplicationDetails () {
            const result = await RestApi.getData(incentiveGrantServiceBaseUrl, `${applicantDetails}/${this.$props.id}`)
            if (result.success) {
                this.application = this.getRelationalData(result.data)
                this.addressInfo = this.getRelationalAddressData(result.data.address_info)
                this.LanguageInfo = this.getRelationalLanguageData(result.data.language_info)
                this.AccadamicInfo = this.getRelationalAccadamicData(result.data.academic_info)
                this.trainingDetails = this.getTrainingData(result.data.training_info)
                this.seminarDetails = result.data.seminar_info
                this.qulificationDetails = result.data.other_info
                this.annexure1Details = result.data.annexure_one_info
                this.annexure2Details = result.data.employee_record_info
                this.annexure3Details = this.getAnnexure3Data(result.data.annexure_three_info)
            }
            this.loading = false
        },
        getRelationalData (data) {
            const cirList = this.$store.state.incentiveGrant.commonObj.circularInfoList.find(cirInfo => cirInfo.value === data.circular_id)
            const canType = this.candidateType.find(canData => canData.value === parseInt(data.candidate_type))
            const canOrg = this.candidateOrg.find(canOrgData => canOrgData.value === parseInt(data.can_org_type))
            const maritStatus = this.maritalStatus.find(mStatus => mStatus.value === parseInt(data.marital_status))
            return Object.assign({}, data,
                { cir_name: cirList !== undefined ? cirList.text_en : '', cir_name_bn: cirList !== undefined ? cirList.text_bn : '' },
                { canType_name: canType !== undefined ? canType.text_en : '', canType_name_bn: canType !== undefined ? canType.text_bn : '' },
                { canOrg_name: canOrg !== undefined ? canOrg.text_en : '', canOrg_name_bn: canOrg !== undefined ? canOrg.text_bn : '' },
                { mStatus_name: maritStatus !== undefined ? maritStatus.text_en : '', mStatus_name_bn: maritStatus !== undefined ? maritStatus.text_bn : '' }
            )
        },
        getRelationalAddressData (data) {
            const areaList = this.getAreaType.find(aType => aType.value === parseInt(data.area_type_id))
            const divList = this.$store.state.commonObj.divisionList.find(div => div.value === data.division_id)
            const disList = this.$store.state.commonObj.districtList.find(dis => dis.value === data.district_id)
            const upaList = this.$store.state.commonObj.upazilaList.find(upa => upa.value === data.upazilla_id)
            const unionList = this.$store.state.commonObj.unionList.find(union => union.value === data.union_id)
            const cityCorList = this.$store.state.commonObj.cityCorporationList.find(city => city.value === data.city_corporation_id)
            const pauroList = this.$store.state.commonObj.pauroshobaList.find(pauro => pauro.value === data.pauroshoba_id)
            const wardList = this.$store.state.commonObj.wardList.find(ward => ward.value === data.ward_id)

            const areaListPer = this.getAreaType.find(aType => aType.value === parseInt(data.area_type_id))
            const divListPer = this.$store.state.commonObj.divisionList.find(div => div.value === data.per_division_id)
            const disListPer = this.$store.state.commonObj.districtList.find(dis => dis.value === data.per_district_id)
            const upaListPer = this.$store.state.commonObj.upazilaList.find(upa => upa.value === data.per_upazilla_id)
            const unionListPer = this.$store.state.commonObj.unionList.find(union => union.value === data.per_union_id)
            const cityCorListPer = this.$store.state.commonObj.cityCorporationList.find(city => city.value === data.per_city_corporation_id)
            const pauroListPer = this.$store.state.commonObj.pauroshobaList.find(pauro => pauro.value === data.per_pauroshoba_id)
            const wardListPer = this.$store.state.commonObj.wardList.find(ward => ward.value === data.per_ward_id)
            return Object.assign({}, data,
                { area_name: areaList !== undefined ? areaList.text_en : '', area_name_bn: areaList !== undefined ? areaList.text_bn : '' },
                { div_name: divList !== undefined ? divList.text_en : '', div_name_bn: divList !== undefined ? divList.text_bn : '' },
                { dis_name: disList !== undefined ? disList.text_en : '', dis_name_bn: disList !== undefined ? disList.text_bn : '' },
                { upa_name: upaList !== undefined ? upaList.text_en : '', upa_name_bn: upaList !== undefined ? upaList.text_bn : '' },
                { union_name: unionList !== undefined ? unionList.text_en : '', union_name_bn: unionList !== undefined ? unionList.text_bn : '' },
                { cityCor_name: cityCorList !== undefined ? cityCorList.text_en : '', cityCor_name_bn: cityCorList !== undefined ? cityCorList.text_bn : '' },
                { pauro_name: pauroList !== undefined ? pauroList.text_en : '', pauro_name_bn: pauroList !== undefined ? pauroList.text_bn : '' },
                { ward_name: wardList !== undefined ? wardList.text_en : '', ward_name_bn: wardList !== undefined ? wardList.text_bn : '' },
                { per_area_name: areaListPer !== undefined ? areaListPer.text_en : '', per_area_name_bn: areaListPer !== undefined ? areaListPer.text_bn : '' },
                { per_div_name: divListPer !== undefined ? divListPer.text_en : '', per_div_name_bn: divListPer !== undefined ? divListPer.text_bn : '' },
                { per_dis_name: disListPer !== undefined ? disListPer.text_en : '', per_dis_name_bn: disListPer !== undefined ? disListPer.text_bn : '' },
                { per_upa_name: upaListPer !== undefined ? upaListPer.text_en : '', per_upa_name_bn: upaListPer !== undefined ? upaListPer.text_bn : '' },
                { per_union_name: unionListPer !== undefined ? unionListPer.text_en : '', per_union_name_bn: unionListPer !== undefined ? unionListPer.text_bn : '' },
                { per_cityCor_name: cityCorListPer !== undefined ? cityCorListPer.text_en : '', per_cityCor_name_bn: cityCorListPer !== undefined ? cityCorListPer.text_bn : '' },
                { per_pauro_name: pauroListPer !== undefined ? pauroListPer.text_en : '', per_pauro_name_bn: pauroListPer !== undefined ? pauroListPer.text_bn : '' },
                { per_ward_name: wardListPer !== undefined ? wardListPer.text_en : '', per_ward_name_bn: wardListPer !== undefined ? wardListPer.text_bn : '' }
            )
        },
        getRelationalLanguageData (data) {
            if (data !== null) {
                const eReadingList = this.englishSkill.find(eReading => eReading.id === parseInt(data.english_reading_skill))
                const eWritingList = this.englishSkill.find(eWriting => eWriting.id === parseInt(data.english_writing_skill))
                const eSpeakingList = this.englishSkill.find(eSpeaking => eSpeaking.id === parseInt(data.english_speaking_skill))
                return Object.assign({}, data,
                    { eReading_name: eReadingList !== undefined ? eReadingList.name : '', eReading_name_bn: eReadingList !== undefined ? eReadingList.name_bn : '' },
                    { eWriting_name: eWritingList !== undefined ? eWritingList.name : '', eWriting_name_bn: eWritingList !== undefined ? eWritingList.name_bn : '' },
                    { eSpeaking_name: eSpeakingList !== undefined ? eSpeakingList.name : '', eSpeaking_name_bn: eSpeakingList !== undefined ? eSpeakingList.name_bn : '' }
                )
            }
        },
        getRelationalAccadamicData (data) {
            return data.map((item) => {
                const scoreList = this.scoreSysList.find(score => score.value === parseInt(item.score_system_id))
                const educationList = this.$store.state.incentiveGrant.commonObj.educationLevelList.find(edu => edu.value === parseInt(item.education_level_id))
                return Object.assign({}, item,
                    { score_name: scoreList !== undefined ? scoreList.text : '', score_name_bn: scoreList !== undefined ? scoreList.text_bn : '' },
                    { education_name: educationList !== undefined ? educationList.text_en : '', education_name_bn: educationList !== undefined ? educationList.text_bn : '' }
                )
            })
        },
        getTrainingData (data) {
            return data.map((item) => {
                const tTypeList = this.traininTypeList.find(tType => tType.value === parseInt(item.training_type))
                const tTitleList = this.traininTitleList.find(tTitle => tTitle.value === parseInt(item.training_title))
                return Object.assign({}, item,
                    { tType_name: tTypeList !== undefined ? tTypeList.text : '', tType_name_bn: tTypeList !== undefined ? tTypeList.text_bn : '' },
                    { tTitle_name: tTitleList !== undefined ? tTitleList.text : '', tTitle_name_bn: tTitleList !== undefined ? tTitleList.text_bn : '' }
                )
            })
        },
        getAnnexure3Data (data) {
            return data.map((item) => {
                const typeList = this.performanceEvaluationList.find(pList => pList.id === parseInt(item.type))
                const yearList = this.evaluationYearList.find(eList => eList.id === parseInt(item.year))
                return Object.assign({}, item,
                    { type_name: typeList !== undefined ? typeList.name : '', type_name_bn: typeList !== undefined ? typeList.name_bn : '' },
                    { year_name: yearList !== undefined ? yearList.name : '', year_name_bn: yearList !== undefined ? yearList.name_bn : '' }
                )
            })
        },
        pdfExport () {
            const reportTitle = this.$i18n.locale === 'en' ? 'Port Wise Allocation Management' : 'বন্দর অনুযায়ী বরাদ্দের ব্যবস্থাপনা'
            ExportPdf.exportPdfDetails(reportTitle, this.application, this, this.addressInfo, this.LanguageInfo, this.AccadamicInfo, this.trainingDetails, this.seminarDetails, this.qulificationDetails, this.annexure1Details, this.annexure2Details, this.annexure3Details)
        },
        getOrganization (orgId) {
            const org = this.$store.state.orgList.find(item => item.value === orgId)
            return org !== undefined ? (this.$i18n.locale === 'bn' ? org.text_bn : org.text_en) : ''
        }
    }
}
</script>
