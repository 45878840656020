
// Approval Committee Setup
export const appCommitteeList = '/barc/config/app-committes/list'
export const appCommitteeStatus = '/barc/config/app-committes/toggle-status'
export const appCommitteeStore = '/barc/config/app-committes/store'
export const appCommitteeUpdate = '/barc/config/app-committes/update'
export const appCommitteeDestroy = '/barc/config/app-committes/destroy'

// Fund Request Routes
export const fundReqList = '/barc/grant-distribution/fund-request/list'
export const fundReqApprove = '/barc/grant-distribution/fund-request/approve'

// Fund Distribution Routes
export const fundDistributionList = '/barc/grant-distribution/fund-distribution/list'
export const getAvailableFund = '/external-user/fund-request/available-fund'
export const fundDistributionShowData = '/barc/grant-distribution/fund-distribution/show'
export const fundDistributionUpdate = '/barc/grant-distribution/fund-distribution/update'
export const fundDistributionStore = '/barc/grant-distribution/fund-distribution/store'

// Approval Committee Setup
export const universityDeputationList = '/barc/grant-distribution/assign-uni-deputation/list'
export const universityDeputationShowData = '/barc/grant-distribution/assign-uni-deputation/show'
export const universityDeputationUpdate = '/barc/grant-distribution/assign-uni-deputation/update'

// Evaluation by selection committee
const evaSelectComm = '/barc/grant-distribution/evaluation-by-selection-committee/'
export const applicantList = evaSelectComm + 'list'
export const getScore = evaSelectComm + 'get-score'
export const assignScoreSave = evaSelectComm + 'assign-score'
export const applicantDetails = '/barc/applicant-panel/application-form/show'
