
<template>
    <b-container fluid>
        <b-row>
            <b-col md="12">
                <iq-card>
                    <template v-slot:body>
                      <b-overlay :show="loading">
                        <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                          <b-form @submit.prevent="handleSubmit(saveData)" @reset.prevent="reset">
                            <b-row>
                              <b-col md="12" class="table-responsive">
                                <b-table-simple bordered>
                                  <b-tr>
                                    <b-th>{{ $t('grantDistribution.criteria') }}</b-th>
                                    <b-th>{{ $t('grantDistribution.fullScore') }}</b-th>
                                    <b-th>{{ $t('grantDistribution.scoreByCandidate') }}</b-th>
                                  </b-tr>
                                  <b-tr>
                                    <b-td>{{ $t('grantDistribution.academicRecords') }}</b-td>
                                    <b-td>{{ mainScore.academic_record }}</b-td>
                                    <b-td>{{ mainScore.app_academic_record }}</b-td>
                                  </b-tr>
                                  <b-tr>
                                    <b-td>{{ $t('grantDistribution.serviceLength') }}</b-td>
                                    <b-td>{{ mainScore.service_length }}</b-td>
                                    <b-td>{{ mainScore.app_service_length }}</b-td>
                                  </b-tr>
                                  <b-tr>
                                    <b-td>{{ $t('grantDistribution.age') }}</b-td>
                                    <b-td>{{ mainScore.age }}</b-td>
                                    <b-td>{{ mainScore.app_age }}</b-td>
                                  </b-tr>
                                  <b-tr>
                                    <b-td>{{ $t('grantDistribution.publication') }}</b-td>
                                    <b-td>{{ mainScore.publications }}</b-td>
                                    <b-td>{{ mainScore.app_publications }}</b-td>
                                  </b-tr>
                                  <b-tr>
                                    <b-td>{{ $t('grantDistribution.foundationTraining') }}</b-td>
                                    <b-td>{{ mainScore.foundation_training }}</b-td>
                                    <b-td>{{ mainScore.app_foundation_training }}</b-td>
                                  </b-tr>
                                  <b-tr>
                                    <b-td>{{ $t('grantDistribution.acr') }}</b-td>
                                    <b-td>{{ mainScore.acr }}</b-td>
                                    <b-td>{{ mainScore.app_acr }}</b-td>
                                  </b-tr>
                                  <b-tr>
                                    <b-td>{{ $t('grantDistribution.selectionCommitteeScore') }}</b-td>
                                    <b-td>
                                      <ValidationProvider name="Score" vid="selection_committee">
                                        <b-form-group
                                          label-for="score"
                                          slot-scope="{ valid, errors }"
                                        >
                                        <b-form-input
                                            v-model="mainScore.selection_committee"
                                            :state="errors[0] ? false : (valid ? true : null)"
                                            readonly
                                            ></b-form-input>
                                          <div class="invalid-feedback">
                                            {{ errors[0] }}
                                          </div>
                                        </b-form-group>
                                      </ValidationProvider>
                                    </b-td>
                                    <b-td>
                                      <ValidationProvider name="Score" rules="required|min_value:1|max_value:@selection_committee">
                                        <b-form-group
                                          label-for="score"
                                          slot-scope="{ valid, errors }"
                                        >
                                        <b-form-input
                                            v-model="assignScore.score"
                                            oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                                            :state="errors[0] ? false : (valid ? true : null)"
                                            ></b-form-input>
                                          <div class="invalid-feedback">
                                            {{ errors[0] }}
                                          </div>
                                        </b-form-group>
                                      </ValidationProvider>
                                    </b-td>
                                  </b-tr>
                                  <b-tr>
                                    <b-td>{{ $t('globalTrans.total') }}</b-td>
                                    <b-td>{{ fullScoreTotal }}</b-td>
                                    <b-td>{{ candidateScoreTotal }}</b-td>
                                  </b-tr>
                                  <b-tr>
                                    <b-td colspan="3">
                                      <b-row>
                                        <b-col lg="6" md="6" sm="12">
                                          <ValidationProvider name="Note (En)" rules="required">
                                            <b-form-group
                                                label-for="note"
                                                slot-scope="{ valid, errors }"
                                            >
                                              <template v-slot:label>
                                                {{$t('grantDistribution.note_en')}} <span class="text-danger">*</span>
                                              </template>
                                                <b-form-textarea
                                                  id="note"
                                                  v-model="assignScore.note"
                                                  :state="errors[0] ? false : (valid ? true : null)"
                                                ></b-form-textarea>
                                              <div class="invalid-feedback">
                                              {{ errors[0] }}
                                              </div>
                                            </b-form-group>
                                          </ValidationProvider>
                                        </b-col>
                                        <b-col lg="6" md="6" sm="12">
                                          <ValidationProvider name="Note (Bn)" vid="note_bn">
                                            <b-form-group
                                                label-for="note_bn"
                                                slot-scope="{ valid, errors }"
                                            >
                                              <template v-slot:label>
                                                {{$t('grantDistribution.note_bn')}}
                                              </template>
                                                <b-form-textarea
                                                  id="note_bn"
                                                  v-model="assignScore.note_bn"
                                                  :state="errors[0] ? false : (valid ? true : null)"
                                                ></b-form-textarea>
                                              <div class="invalid-feedback">
                                              {{ errors[0] }}
                                              </div>
                                            </b-form-group>
                                          </ValidationProvider>
                                        </b-col>
                                      </b-row>
                                    </b-td>
                                  </b-tr>
                                </b-table-simple>
                              </b-col>
                            </b-row>
                            <div class="row">
                              <div class="col-sm-3"></div>
                              <div class="col text-right">
                                  <b-button type="submit" variant="primary" class="mr-2" @click="isApprove = true">{{ $t('globalTrans.approve') }}</b-button>
                                  &nbsp;
                                  <b-button type="submit" variant="danger" class="mr-1"  @click="isApprove = false">{{ $t('globalTrans.reject') }}</b-button>
                                  &nbsp;
                                  <b-button variant="info" class="mr-1" @click="$bvModal.hide('modal-4')">{{ $t('globalTrans.cancel') }}</b-button>
                              </div>
                            </div>
                          </b-form>
                        </ValidationObserver>
                      </b-overlay>
                    </template>
                </iq-card>
            </b-col>
        </b-row>
    </b-container>
</template>
<script>
import RestApi, { incentiveGrantServiceBaseUrl } from '@/config/api_config'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { getScore, assignScoreSave } from '../../api/routes'

export default {
  props: ['id'],
  components: {
    ValidationObserver,
    ValidationProvider
  },
  data () {
    return {
      loading: false,
      mainScore: '',
      assignScore: {
        score: '',
        note: '',
        note_bn: '',
        status: ''
      },
      isApprove: false,
      fullScoreTotal: 0,
      candidateScoreTotal: 0,
      tmpCandidateScoreTotal: 0
    }
  },
  watch: {
    isApprove: function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.assignScore.status = newVal ? 2 : 3
      }
    },
    'assignScore.score': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.calculateScore(newVal)
      }
    }
  },
  created () {
    this.getScore(this.$props.id)
  },
  methods: {
    async getScore (applicantId) {
      this.loading = true
      RestApi.getData(incentiveGrantServiceBaseUrl, getScore + '/' + applicantId).then(response => {
        this.loading = false
        if (response.success) {
          this.mainScore = response.data
          this.fullScoreTotal = response.data.full_score_total
          this.tmpCandidateScoreTotal = this.candidateScoreTotal = response.data.candidate_score_total
        }
      })
    },
    async saveData () {
      this.loading = true
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      let result = null
      const loadingState = { loading: false, listReload: false }
      const formData = Object.assign({}, this.assignScore, { app_general_info_id: this.$props.id })
      result = await RestApi.postData(incentiveGrantServiceBaseUrl, assignScoreSave, formData)
      loadingState.listReload = true
      this.$store.dispatch('mutateCommonProperties', loadingState)
      this.loading = false
      if (result.success) {
          // push notification
          // const notification = result.notification
          // this.$socket.emit('send-notification', notification)

          this.$store.commit('incentiveGrant/mutateIncentiveGrantServiceProperties', { hasDropdownLoaded: false })
          this.$toast.success({
            title: this.$t('globalTrans.success'),
            message: this.$t('globalTrans.save_msg'),
            color: '#D6E09B'
          })
          this.$bvModal.hide('modal-4')
      } else {
          this.$refs.form.setErrors(result.errors)
      }
    },
    calculateScore (newVal) {
      const total = (newVal <= this.mainScore.selection_committee) ? (parseFloat(this.tmpCandidateScoreTotal) + parseFloat(newVal)) : parseFloat(this.mainScore.selection_committee + this.tmpCandidateScoreTotal)
      this.candidateScoreTotal = total
    }
  }
}
</script>
