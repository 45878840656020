
<template>
  <b-container fluid>
    <iq-card>
      <template v-slot:headerTitle>
        <h4 class="card-title">{{ $t('grantDistribution.evaluation_by_selection_committee') }}</h4>
      </template>
      <template v-slot:body>
        <ValidationObserver ref="form" v-slot="{ handleSubmit, reset }">
          <b-form  @submit.prevent="handleSubmit(searchData)" @reset.prevent="reset">
            <b-row>
              <b-col xs="12" sm="12" md="6">
                <ValidationProvider name="Applicant_id" vid="applicant_id">
                  <b-form-group
                    class="row"
                    label-cols-sm="4"
                    label-for="applicant_id"
                    slot-scope="{ valid, errors }"
                  >
                    <template v-slot:label>
                      {{$t('grantDistribution.applicant_id')}}
                    </template>
                    <b-form-input
                        id="applicant_id"
                        v-model="search.application_id"
                        :state="errors[0] ? false : (valid ? true : null)"
                    ></b-form-input>
                    <div class="invalid-feedback">
                      {{ errors[0] }}
                    </div>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
              <b-col xs="12" sm="12" md="6">
                <ValidationProvider name="From Date" vid="from_date">
                  <b-form-group
                    class="row"
                    label-cols-sm="4"
                    label-for="from_date"
                    slot-scope="{ valid, errors }"
                  >
                    <template v-slot:label>
                      {{$t('grantDistribution.applicant_date_from')}}
                    </template>
                    <b-form-input
                        id="datepicker"
                        v-model="search.from_date"
                        :state="errors[0] ? false : (valid ? true : null)"
                    ></b-form-input>
                    <div class="invalid-feedback">
                      {{ errors[0] }}
                    </div>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
              <b-col xs="12" sm="12" md="6">
                <ValidationProvider name="To Date" vid="to_date">
                  <b-form-group
                    class="row"
                    label-cols-sm="4"
                    label-for="to_date"
                    slot-scope="{ valid, errors }"
                  >
                    <template v-slot:label>
                      {{$t('grantDistribution.applicant_date_to')}}
                    </template>
                    <b-form-input
                        id="datepicker"
                        v-model="search.to_date"
                        :state="errors[0] ? false : (valid ? true : null)"
                    ></b-form-input>
                    <div class="invalid-feedback">
                      {{ errors[0] }}
                    </div>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
              <b-col xs="12" sm="12" md="6">
                <b-button type="submit" variant="primary float-right">{{ $t('globalTrans.search')}}</b-button>
              </b-col>
            </b-row>
          </b-form>
        </ValidationObserver>
      </template>
    </iq-card>
    <b-row>
      <b-col md="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">{{ $t('grantDistribution.applicant_list_selection') }} {{ $t('globalTrans.list') }}</h4>
          </template>
          <template v-slot:body>
            <b-overlay :show="loadingState">
              <b-row>
                <b-col md="12" class="table-responsive">
                  <b-table thead-class="table_head" striped :emptyText="$t('globalTrans.noDataFound')" show-empty bordered hover :items="applicants" :fields="columns" aria-hidden="loading | listReload ? 'true' : null">
                    <template v-slot:cell(index)="data">
                      {{ $n(data.index + pagination.slOffset) }}
                    </template>
                    <template v-slot:cell(updated_at)="data">
                      {{ data.item.updated_at | dateFormat }}
                    </template>
                    <template v-slot:cell(application_id)="data">
                      {{ $n(data.item.application_id, { useGrouping: false }) }}
                    </template>
                    <template v-slot:cell(status)="data">
                      {{ getStatus(data.item.status) }}
                    </template>
                    <template v-slot:cell(action)="data">
                      <!-- <b-button v-b-modal.modal-5 title="Applicant Details" variant=" iq-bg-success mr-1" size="sm" @click="details(data.item)"><i class="ri-eye-line m-0 "></i></b-button> -->
                      <!-- <b-button v-if="data.item.status === 1" v-b-modal.modal-4 variant=" iq-bg-success" title="Assign Score" size="sm" @click="edit(data.item)"><i class="ri-add-line m-0"></i></b-button> -->
                      <a href="javascript:" class="btn_table_action table_action_view" :title="$t('globalTrans.details')" v-b-modal.modal-5 size="sm" @click="details(data.item)">
                          <i class="fas fa-eye"></i>
                      </a>
                      <a href="javascript:" v-if="data.item.status === 1" class="btn_table_action table_action_edit" title="Assign Score" v-b-modal.modal-4 @click="edit(data.item)">
                          <i class="fas fa-check"></i>
                      </a>
                    </template>
                  </b-table>
                  <b-pagination
                    align="center"
                    v-model="pagination.currentPage"
                    :per-page="pagination.perPage"
                    :total-rows="pagination.totalRows"
                    @input="searchData"
                  />
                </b-col>
              </b-row>
            </b-overlay>
          </template>
        </iq-card>
      </b-col>
    </b-row>
    <b-modal id="modal-4" size="lg" :title="formTitle" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
      <AssignScore :id="editItemId" :key="editItemId"/>
    </b-modal>
    <b-modal id="modal-5" size="xl" :title="DetailsTitle" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
      <b-button @click="pdfExport" class="ml-4 btn-success water-test-pdf-button">
        {{  $t('globalTrans.export_pdf') }}
      </b-button>
      <Details :id="item_id" ref="details" />
    </b-modal>
  </b-container>
</template>
<script>
import AssignScore from './AssignScore'
import Details from './Details'
import RestApi, { incentiveGrantServiceBaseUrl } from '@/config/api_config'
import { applicantList } from '../../api/routes'
import ModalBaseMasterList from '@/mixins/modal-base-master-list'
import flatpickr from 'flatpickr'

export default {
  mixins: [ModalBaseMasterList],
  components: {
    AssignScore, Details
  },
  data () {
    return {
      search: {
        application_id: '',
        from_date: '',
        to_date: ''
      },
      item_id: '',
      applicants: []
    }
  },
  created () {
    this.loadData()
  },
  mounted () {
    flatpickr('#datepicker', {})
  },
  computed: {
    DetailsTitle () {
      return this.$t('grantDistribution.application_score_details')
    },
    formTitle () {
      return this.$t('grantDistribution.assign_score')
    },
    columns () {
      const labels = [
        { label: this.$t('globalTrans.sl_no'), class: 'text-center' },
        { label: this.$t('globalTrans.application_id'), class: 'text-center' },
        { label: this.$t('grantDistribution.applicant_name'), class: 'text-center' },
        { label: this.$t('grantDistribution.application_submit_date'), class: 'text-center' },
        { label: this.$t('globalTrans.status'), class: 'text-center' },
        { label: this.$t('globalTrans.action'), class: 'text-center' }
      ]

      let keys = []

      if (this.$i18n.locale === 'bn') {
        keys = [
          { key: 'index' },
          { key: 'application_id' },
          { key: 'name_bn' },
          { key: 'updated_at' },
          { key: 'status' },
          { key: 'action' }
        ]
      } else {
        keys = [
          { key: 'index' },
          { key: 'application_id' },
          { key: 'name' },
          { key: 'updated_at' },
          { key: 'status' },
          { key: 'action' }
        ]
      }

      return labels.map((item, index) => {
        return Object.assign(item, keys[index])
      })
    }
  },
  methods: {
    searchData () {
      this.loadData()
    },
    edit (item) {
      this.editItemId = item.id
    },
    details (item) {
      this.item_id = item.id
    },
    loadData () {
      const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
      RestApi.getData(incentiveGrantServiceBaseUrl, applicantList, params).then(response => {
        if (response.success) {
          this.applicants = this.getRelationalData(response.data.data)
          this.paginationData(response.data)
        }
        this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
      })
    },
    getRelationalData (data) {
      const orgList = this.$store.state.commonObj.organizationProfileList
      const datas = data.map(item => {
          const orgData = orgList.find(organization => organization.value === item.can_org_id)
          const orgObj = {
            organization_en: orgData !== undefined ? orgData.text_en : '',
            organization_bn: orgData !== undefined ? orgData.text_bn : ''
          }
          return Object.assign({}, item, orgObj)
      })
      return datas
    },
    pdfExport () {
      this.$refs.details.pdfExport()
    },
    getStatus (status) {
      if (status === 1) {
        return this.$t('globalTrans.finalSave')
      } else if (status === 2) {
        return this.$t('globalTrans.approve')
      } else if (status === 3) {
        return this.$t('globalTrans.reject')
      }
    }
  }
}
</script>
